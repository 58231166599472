/*SECTION 1 CSS*/

.video-row-desktop {
  position: relative;
  overflow: hidden;

  > video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: brightness(90%);
  }
}

@media (max-width: 767px) {
  .video-row-desktop {
    height: 0 !important;
    padding-bottom: 100%;

    > video {
      width: auto;
      height: 100%;
    }
  }

  .welcome-div {
    bottom: 2.5em;
    transform: translateX(-50%);
  }
}

@media (min-width: 768px) {
  .video-row-desktop {
    height: 70vh !important;

    > video {
      width: 100%;
      height: auto;
    }
  }

  .welcome-div {
    top: 35vh;
    transform: translate(-50%, -50%);
  }
}

.welcome-div {
  position: absolute;
  left: 50%;
  margin-right: -50%;
}

@media (max-width: 767px) {
  .welcome-text-v2-sm-down {
    color: white;
    font-size: 2.5rem !important;
    margin-top: 0px;
    margin-bottom: 16px;
  }

  .welcome-sub-text-v2-sm-down {
    color: white;
    font-weight: 500;
    font-size: 1rem !important;
    margin-top: 0px;
    margin-bottom: 16px;
  }

  .welcome-text-v2-sm-down-black {
    color: black;
    font-size: 2.5rem !important;
    margin-top: 0px;
    margin-bottom: 16px;
  }

  .welcome-sub-text-v2-sm-down-black {
    color: black;
    font-weight: 500;
    font-size: 1rem !important;
    margin-top: 0px;
    margin-bottom: 16px;
  }
}

@media (min-width: 768px) {
  .welcome-text-v2-sm-up {
    color: white;
    font-size: 3rem !important;
    margin-top: 0px;
    margin-bottom: 8px;
  }

  .welcome-sub-text-v2-sm-up {
    color: white;
    font-weight: 500;
    font-size: 1.6rem !important;
    margin-top: 0px;
    margin-bottom: 24px;
  }

  .p-e-48-sm-up {
    padding-right: 48px;
    padding-left: 48px;
  }
}

/*SECTION 2 CSS*/

.title-card-dichotomy {
  margin-top: 8px;
  font-size: 2rem !important;
  font-family: "RecoletaAlt" !important;
  font-weight: 600 !important;
  color: black;
}

.des-card-dichotomy {
  font-size: 0.875rem !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
  color: black;
}

@media (min-width: 768px) {
  .card-container-left-dichotomy-sm-up {
    max-width: 420px;
    float: right;
  }

  .card-container-right-dichotomy-sm-up {
    max-width: 420px;
    margin-top: 32px;
    float: left;
  }

  .card-container-left-action-sm-up {
    max-width: 420px;
    min-width: 320px;
    float: right;
  }

  .card-container-right-action-sm-up {
    max-width: 420px;
    min-width: 320px;
    float: left;
  }

  .card-container-center-action-sm-up {
    max-width: 420px;
    min-width: 320px;
    margin: 0 auto;
  }
}

.dichotomy-price {
  font-size: 1.375rem !important;
  font-family: "RecoletaAlt" !important;
  color: black;
}

.dichotomy-currency {
  text-align: center;
  display: inline-block;
  font-size: 0.625rem !important;
  vertical-align: middle;
  margin-top: 0px;
  color: black;
}

.know-how-video-row {
  max-height: 70vh;
  overflow: hidden;

  > video {
    filter: brightness(90%);
  }
}

.help-text {
  font-size: 0.875rem !important;
  font-family: "Inter" !important;
  font-weight: 600 !important;
  color: black;
}

.newsletter-modal .modal-wrapper {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/assets/img/style/frame_in_hands.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (min-width: 767px) {
  .newsletter-modal .modal-wrapper {
    width: 65vh;
    height: 65vh;
  }
}
