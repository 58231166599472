/*CSS FROM LANDING PAGE//////////////////////////////////////////////////////*/

@media (min-width: 992px) {
  h1 {
    font-size: 2.25rem !important;
    font-family: "RecoletaAlt" !important;
    font-weight: 600 !important;
  }
}

h1 {
  font-size: 1.75rem !important;
  font-family: "RecoletaAlt" !important;
  font-weight: 600 !important;
}

h2 {
  font-size: 1.5rem !important;
  font-family: "RecoletaAlt" !important;
}

h3 {
  font-size: 1.25rem !important;
  font-family: "Point" !important;
}

h4 {
  font-size: 0.875rem !important;
  font-family: "Point" !important;
}

p {
  font-size: 0.85rem !important;
  font-family: "Inter" !important;
}

.p1 {
  font-size: 1rem !important;
  font-family: "Inter" !important;
}

.p1-25 {
  font-size: 1.25rem !important;
  font-family: "Inter" !important;
}

.justify-text {
  text-align: justify !important;
}

.dark-link {
  color: var(--ion-color-dark) !important;
  text-decoration: none;
}

.dark-link:hover {
  color: var(--ion-color-dark-shade) !important;
  text-decoration: none;
}

.text-button {
  font-size: 0.75rem !important;
  font-family: "Inter" !important;
  letter-spacing: 2px;
}

.text-fab-button {
  font-family: "Point" !important;
  letter-spacing: 2px;
  font-size: 0.6rem;
  text-align: left;
}

.text-collection {
  font-family: "Inter" !important;
  font-size: 0.625rem;
  letter-spacing: 2px;
}

.text-stage {
  font-family: "Inter" !important;
  font-size: 1rem;
  letter-spacing: 2px;
}

.text-product-title {
  font-family: "Inter" !important;
  font-size: 1.125rem !important;
  display: block;
}

.text-product-short-des {
  font-family: "Inter" !important;
  font-size: 0.75rem !important;
  display: block;
}

.text-product-long-des {
  font-family: "Inter" !important;
  font-size: 0.75rem !important;
  letter-spacing: 1px;
}

.text-product-tag {
  font-family: "Inter" !important;
  font-size: 0.625rem !important;
  letter-spacing: 1px;
}

.text-details-section {
  font-family: "Inter" !important;
  font-size: 1rem !important;
  letter-spacing: 2px;
}

.text-arttype-title {
  font-family: "Inter" !important;
  font-size: 0.75rem !important;
  letter-spacing: 2px;
}

.text-arttype {
  font-family: "Inter" !important;
  font-size: 0.8rem !important;
}

.text-custom-section {
  font-family: "Inter" !important;
  letter-spacing: 2px;
  font-size: 1rem !important;
}

.text-price {
  font-size: 2.25rem !important;
  font-family: "RecoletaAlt" !important;
}

.text-price-card {
  font-size: 1.1rem !important;
  font-family: "RecoletaAlt" !important;
  margin-top: 0px;
}

.text-price-plus {
  font-size: 1.1rem !important;
  font-family: "Inter" !important;
  margin-top: 0px;
}

.text-price-mobile {
  font-size: 1.5rem !important;
  font-family: "RecoletaAlt" !important;
}

.currency-card {
  text-align: center;
  display: inline-block;
  font-size: 0.625rem !important;
  vertical-align: middle;
  margin-top: 0px;
}

.img-unselected {
  filter: saturate(0%) brightness(70%) contrast(10%);
}

.text-footer-title {
  font-size: 0.75rem !important;
  font-family: "Point" !important;
  letter-spacing: 2px;
}

.text-footer-link {
  font-size: 0.75rem !important;
  font-family: "Inter" !important;
}

.text-footer-city {
  font-size: 0.75rem !important;
  font-family: "Point" !important;
  letter-spacing: 2px;
}

.text-wizard {
  font-family: "Inter" !important;
  font-size: 0.75rem !important;
  letter-spacing: 2px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 8px;
  padding-bottom: 8px;
}

.text-wizard-mobile {
  font-family: "Inter" !important;
  font-size: 0.5rem !important;
  letter-spacing: 2px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  display: block !important;
}

.text-wizard-redo-mobile {
  font-family: "Inter" !important;
  font-size: 0.625rem !important;
  letter-spacing: 2px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  text-decoration: underline;
}

.iconSize {
  font-size: 64px;
}

.text-guid-specs {
  font-family: "Inter" !important;
  font-weight: 300;
  font-size: 0.75rem !important;
  letter-spacing: 1px;
}

.text-guide-button {
  font-size: 0.625rem !important;
  font-family: "Point" !important;
  letter-spacing: 1px;
}

.text-delivery-price-dark {
  color: var(--ion-color-dark) !important;
  text-decoration: underline;
  text-underline-offset: 5px;
  font-size: 0.7rem !important;
  display: block !important;
  font-weight: 300;
}

.text-delivery-price {
  color: var(--ion-color-primary) !important;
  text-decoration: underline;
  text-underline-offset: 5px;
  font-size: 0.7rem !important;
  font-weight: 300;
  display: block !important;
}

.text-delivery-price-mobile {
  color: var(--ion-color-primary) !important;
  text-decoration: underline;
  text-underline-offset: 5px;
  font-size: 0.8rem !important;
  display: block !important;
  margin: auto;
  font-weight: 300;
}

/*END////////////////////////*/

.div-line {
  width: 1.5rem;
  height: 0.7rem;
  margin: 0 auto;
  margin-top: -1.2rem;
  margin-left: 50%;
}

.div-line-home {
  width: 3.5rem;
  height: 0.7rem;
  margin: 0 auto;
  margin-top: -1.4rem;
  margin-left: 50%;
}

.white-highlight {
  border-radius: 1px;
  padding-left: 3px;
}

.white-highlight {
  background: #eccbb4; /* Default color, all browsers background:var(--ion-color-light-shade) */
}

.green-highlight {
  background: #eccbb4;
}

.ion-inline-bold {
  display: "inline";
  font-weight: "bold";
}

.image-cover-landing-mobile {
  background: url("../assets/cover1.jpg") no-repeat 52% center/cover;
  background-size: cover;
  height: 480px;
}

.image-cover-landing {
  background: url("../assets/cover1.jpg") no-repeat 52% center/cover;
  background-size: cover;
  height: 800px;
}

.gallery-wall {
  background: no-repeat 52% center/cover;
  background-size: cover;
  /*height:680px;*/
  height: calc(100vh - 300px);
  transition: background-image 1s;
}

.image-cover {
  background: no-repeat 52% center/cover;
}

.welcome-text {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, -25%);
  margin-right: -50%;
}

.welcome-title {
  font-size: 2.625rem !important;
  display: block;
}

@media (min-width: 992px) {
  .welcome-title-lg-up {
    font-size: 2.625rem !important;
    display: block;
  }
}

@media (max-width: 991px) {
  .welcome-title-md-down {
    font-size: 2rem !important;
    display: block;
  }
}

.card-icon {
  max-width: 90px !important;
  display: block;
  margin: auto;
}

.handmade-section {
  background: no-repeat 52% center/cover;
  background-size: cover;
  min-height: 520px;
  transition: background-image 1s;
}

.text-home-card {
  font-family: "Inter" !important;
  font-size: 0.625rem !important;
  letter-spacing: 2px !important;
}

.img-invert {
  filter: invert(1);
}

.fade-out {
  opacity: 0 !important;
  max-height: 0 !important;
  transition: opacity 0.2s;
}

.fade-in {
  opacity: 1 !important;
  max-height: 100% !important;
  transition: opacity 0.21s 0.2s;
}

.fade-out-wall {
  opacity: 0 !important;
  max-height: 0 !important;
  transition: max-height 0s 0.2s, opacity 0.2s;
}

.fade-in-wall {
  opacity: 1 !important;
  max-height: 1000px !important;
  transition: max-height 0s 0.2s, opacity 0.2s 0.2s;
}

.know-how-section {
  background: no-repeat 52% center/cover;
  background-size: cover;
  height: 320px;
  transition: background-image 1s;
}

.know-how-text {
  position: absolute;
  bottom: 64px;
}

.pricing-section {
  background: no-repeat 52% center/cover;
  background-size: cover;
  height: 420px;
  transition: background-image 1s;
}

.our-mission-section {
  background: no-repeat 52% center/cover;
  background-size: cover;
  height: 380px;
  transition: background-image 1s;
}

.image-section-60vh-top {
  background: no-repeat 52% top/cover;
  background-size: cover;
  height: 60vh;
  transition: background-image 1s;
}

.cart-badge-desktop {
  margin-left: -10px;
  margin-top: -12px;
}

.cart-badge-mobile {
  margin-left: -17px;
  margin-top: -17px;
}

.-no-shadow {
  box-shadow: 0 0 black !important;
}

.rounded-input {
  height: 100%;
  width: 100%;
  border-radius: 28px;
  margin-bottom: 10px;
  --ion-color-base: var(--ion-color-secondary-shade);
}

/*CSS FROM LANDING PAGE//////////////////////////////////////////////////////*/

.ion-scroll-custom {
  white-space: nowrap;
  height: 120px;
  overflow: hidden;
}

.scroll-item {
  display: inline-block;
}

.selectable-icon {
  margin: 10px 20px 10px 20px;
  color: red;
  font-size: 100px;
}

.ion-avatar img {
  margin: 10px;
}

/* Hide ion-content scrollbar */
::-webkit-scrollbar {
  display: none;
}

.custom-slide {
  width: 100px;
}

.ion-gallery-collection {
  border-style: dotted;
  border-color: var(--ion-color-primary);
}

span {
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 130px;
  height: 150px;
}

ion-slide:hover {
  cursor: pointer;
}

span ion-img {
  width: 100px;
  height: 100px;
}

.ion-slide-menu {
  width: 100px !important;
  height: 140px !important;
  float: left;
  margin-left: 0.2rem !important;
  margin-right: 0.2rem !important;
  margin-bottom: 0.875rem !important;
  margin-top: 0.2rem !important;
}

.ion-slide-moulding {
  width: 100px !important;
  height: 150px !important;
  float: left;
  margin-left: 0.2rem !important;
  margin-right: 0.2rem !important;
  margin-bottom: 0.875rem !important;
  margin-top: 0.2rem !important;
}

.span-moulding {
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 100px;
  height: 200px;
}

.ion-slide-half {
  width: 40% !important;
}

.image-cover {
  /* --background: url("https://images.unsplash.com/photo-1513161455079-7dc1de15ef3e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60")  no-repeat 52% center/cover; */
  --background: url("../assets/cover1.jpg") no-repeat 52% center/cover;
  --background-size: cover;
}

.ion-grid-custom {
  height: 100%;
}

.ion-row-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

ion-text.ion-text-left {
  margin-right: auto;
}

.ion-underline {
  text-decoration: underline;
}

.ion-bold {
  font-weight: bolder;
}

.ion-block {
  display: block;
}

.ion-inline {
  display: inline;
}

.ion-full-height {
  height: 100%;
}

.equal-sized-item {
  height: 65% !important; /*set value in percentage for each item, i.e for 6 items will be 100/6 = 16.7%*/
}

.equal-sized-item-desktop {
  height: 50% !important; /*set value in percentage for each item, i.e for 6 items will be 100/6 = 16.7%*/
}

.slider-slides {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100%;
}

input {
  display: none;
}

.buttonC {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 35px;
  margin: 8px;
  cursor: pointer;
}

.viewSwitch {
  display: inline-block;
  position: relative;
  width: 28px;
  margin: 2px;
  cursor: pointer;
}

.buttonC span {
  display: block;
  position: absolute;
  width: 35px;
  height: 35px;
  padding: 0;
  top: 0%;
  left: 0%;
  border-radius: 100%;
  background: #eeeeee;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  transition: ease 0.2s;
}

input[type="radio"]:checked + .buttonC span {
  transform: scale(1.1);
  border-radius: 100%;
  box-shadow: 0px 0px 1px 3px rgba(0, 0, 0, 0.26);
}

.buttonC span:hover {
  transform: scale(1.2);
}

.extra-white .buttonC span {
  background: #fefefe;
}

.night-black .buttonC span {
  background: #16160d;
}

.cream-beige .buttonC span {
  background: #FAFAE8;
}

.light-gray .buttonC span {
  background: #F0EEE2;
}

.storm-gray .buttonC span {
  background: #606261;
}

.blue-sky .buttonC span {
  background: #78A0B7;
}

.blue-royal .buttonC span {
  background: #19375D;
}

.green-prairie .buttonC span {
  background: #809769;
}

.green-forest .buttonC span {
  background: #375341;
}

.yellow .buttonC span {
  background: #F3E069;
}

.red-royal .buttonC span {
  background: #A60E29;
}

.antique .buttonC span {
  background: #F9F1D8;
}
