:root {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

ion-backdrop {
  opacity: 0.3;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.popover-class {
  --background: white;
  color: black;
  text-align: center;
  font-size: 1.5rem !important;
  font-family: "LGCustomBold" !important;
  transition: all 250ms ease-in-out 0s;
}

.disable-select {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.line-through {
  text-decoration: line-through !important;
}

.swiper-pagination {
  bottom: -2px !important;
}

.swiper-wrapper {
  padding-bottom: 24px !important;
}

ion-back-button {
  --color: var(--ion-color-primary-contrast);
}

ion-toolbar {
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-top: 3px;
}

.xl-button {
  --padding-start: 48px;
  --padding-end: 48px;
}

.no-ripple {
  --ripple-color: transparent;
}

.y-align {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

@media (min-width: 992px) {
  .padding-top-lg-up {
    padding-top: 32px !important;
  }
}

@media (min-width: 992px) {
  .padding-home-lg-up {
    padding: 64px !important;
  }
}

@media (max-width: 991px) {
  .padding-home-md-down {
    padding: 32px !important;
  }
}

@media (min-width: 992px) {
  .rotate-lg-up {
    transform: rotate(0deg) !important;
  }
}

@media (max-width: 991px) {
  .rotate-md-down {
    transform: rotate(90deg) !important;
  }
}
